.dashboard-top-list{
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1rem; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
    &:hover{
      box-shadow:0px 0px 16px rgba(0, 0, 0, 0.50);
    }
    h4{
      font-size: 18px;
    }
    h4{
      span{
        font-weight: 700;
        font-size: 15px;
      }
    }
  }
}

.grid .card-body{
  flex: 1 1 auto;
  padding: 1.25rem 0rem 0;
  text-align: center;}

.grid  .card {
      padding:10px;
      border-radius:5%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
          &:hover{ 
            box-shadow:0px 0px 16px rgba(0, 0, 0, 0.50);
    }
}

.card-body img{max-width: 100%;}
.card-body .card-title{text-align:center; font-size:16px;}
.card-body .card-title a:hover{color: #4458b8  !important}

p.button-design{
  text-transform: uppercase;
  font-size: 15px!important;
  color: #000;
  padding: 7px 0px 7px 10px;
  margin-bottom: 0;
  cursor: pointer;
  transition: .7s;
  text-decoration-color: transparent;
  &:hover{ text-decoration: underline 2px; text-decoration-color: #4458b8; } 
}

p.active{text-decoration: underline 2px; text-decoration-color: #4458b8;}

.integration_img img{
  max-width: 200px;
  height: auto;
  margin: auto;
  width: 100%;
}
.user .card{border-right: 1px solid #cacaca; text-align: center; box-shadow: none;
  &:hover{ transform: scale(1.2);transition-duration: .2s; border-right: 1px solid transparent;}
}

.box {
  $btn-color: #979695;
  $btn-hover-color: #cacaca;
  $offset: 0.3em;
  border-color: $btn-color;
  color: $btn-color;  
  box-shadow: $offset $offset 0 $btn-hover-color;
  &:hover {
    box-shadow: ($offset) ($offset) 0 $btn-color;
    background-color:#E6E4E4	 ;
    border-color: $btn-color;
    transition-duration: .5s; 
    color: #000;
  }
}

@media(max-width:778px){
  .user .card{ margin-top: 10px;
  }
 .tootip_img {text-align: center;}
}
@media(max-width:481px){
  .user .card{border:none}
  .pagination {
    display: flex;
    justify-content: center;
  }
}
.tab
{
  overflow: auto;
}
.tabl td{
  width: 5%;
  // table-layout: auto;
  &:first-child{
    width: 15%;
  }
}
.tabl td button{
  width: 100%;
}


.tab .card-body{
  padding: 1.25rem 1.25rem 0;
}


.apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-z-value{
  margin-left: 0!important;
}



