@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body{
  padding-top:60px;
font-family: 'Roboto', sans-serif;
font-weight:bold;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flex-container > div {
padding-top:10px;
}
.paginate-ctn{
    display: flex;
}
.round-effect{
  color:#DD4124;
  cursor:pointer;
/*   border:1px solid red; */
  
  font-size:16px;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  padding-top:9px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin:5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
   text-decoration: none;
}

.round-effect:hover{
  text-decoration: none;
  background:blue;
  color:#FFF;
}
.round-effect:hover a {
  text-decoration: none;
  color:#FFF;
}

.active-pagination{
  background:blue;
  color:#FFF;
}
a:link {
  text-decoration: none;
  color:#DD4124;
}